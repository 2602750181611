/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/// cards

$color: #c0c0c0;
$background: #f0f0f0;
$gutter: 10px;
$font-size: 16px;

$card-background: #fff;
$card-padding: 4px;
$card-width: 100px;
$card-height: 165px;
$card-color-red: #e44145;
$card-color-black: #252525;
$card-symbol-size: 40px;

body {
  padding: 0;
  margin: 0;
  background: $background;
}

.cards {
  text-align: center;
  max-width: 640px;
  margin: 0 auto;
}

.card {
  position: relative;
  display: inline-block;
  width: $card-width;
  height: $card-height;
  font: $font-size "Trebuchet MS";

  @media all and (max-width: 768px) {
    width: calc($card-width / 2);
    height: calc($card-height / 2);
  }

  margin: $gutter;
  border-radius: $card-padding;
  background: $card-background;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

  &:before,
  &:after {
    content: attr(value);
    position: absolute;
    width: 12px;
    text-align: center;
    letter-spacing: -2px;
  }

  &:before {
    top: $card-padding;
    left: $card-padding;
  }

  &:after {
    bottom: $card-padding;
    right: $card-padding;
    transform: rotate(180deg);
  }

  &.heart {
    color: $card-color-red;

    &:before,
    &:after {
      content: attr(value) " ♥";
    }
  }

  &.spade {
    color: $card-color-black;

    &:before,
    &:after {
      content: attr(value) " ♠";
    }
  }

  &.diamond {
    color: $card-color-red;

    &:before,
    &:after {
      content: attr(value) " ♦";
    }
  }

  &.club {
    color: $card-color-black;

    &:before,
    &:after {
      content: attr(value) " ♣";
    }
  }
}

.card__inner {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 6 * $card-padding;
  left: 6 * $card-padding;
  right: 6 * $card-padding;
  bottom: 6 * $card-padding;

  @media all and (max-width: 768px) {
    display: none;
  }

  &--centered {
    justify-content: center;
  }
}

.card__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--centered {
    justify-content: center;
  }
}

.card__symbol {
  display: inline-block;
  height: $card-symbol-size;
  text-align: center;

  &:before {
    font-size: $card-symbol-size;
    line-height: $card-symbol-size;
  }

  .heart &:before {
    content: "♥";
  }

  .spade &:before {
    content: "♠";
  }

  .diamond &:before {
    content: "♦";
  }

  .club &:before {
    content: "♣";
  }

  &--rotated,
  &:last-of-type {
    transform: rotate(180deg);
  }

  &:first-of-type {
    transform: rotate(0);
  }

  &--big {
    flex-basis: 0.25 * $card-height;
  }

  &--huge {
    flex-basis: 0.45 * $card-height;
  }
}

.title {
  font: 18px "Trebuchet MS";
  color: #444;
  text-align: center;
}
